import './bootstrap';

import Vue from 'vue';
window.Vue = require('vue');

Vue.component('bs4-typeahead', require('./components/bs4-controls/Typeahead.vue').default);
Vue.component('bs4-tag-input', require('./components/bs4-controls/TagInput.vue').default);
Vue.component('bs4-datepicker', require('./components/bs4-controls/Datepicker.vue').default);
Vue.component('bs4-search-field', require('./components/bs4-controls/SearchField.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.addEventListener('DOMContentLoaded', function() {
    const app = new Vue({
        el: '#app',
    });
});

